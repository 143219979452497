import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Animation, Confetti } from '@/pages/Schedule/components';
import { Box, Button, Flex, Heading, Text, VStack } from '@/ui';

export default function Success(): JSX.Element {
  const { t } = useTranslation('createAiSchedule');
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  return (
    <>
      <Confetti />

      <Flex align="center" h="100%" justify="center" pb="5%">
        <VStack gap={{ base: 4, lg: 10 }} maxW="42rem">
          <Box>
            <Animation animation="mascot" size={300} />

            <Heading
              fontWeight="bold"
              textAlign="center"
              size={{
                base: 'md',
                sm: 'lg',
                md: 'xl',
              }}
            >
              {t('welcome_title')}
            </Heading>
          </Box>

          <Text
            fontWeight="medium"
            px={{ base: '4', md: '36' }}
            py="0"
            textAlign="center"
          >
            {t('welcome_description')}
          </Text>

          <Button
            type="button"
            variant="link"
            onClick={() => {
              trackEvent('prompt:click enter-schedule');
              navigate('', {
                replace: true,
                state: { welcome: false },
              });
            }}
          >
            {t('confirm')}
          </Button>
        </VStack>
      </Flex>
    </>
  );
}
